import { Button } from '@mui/material'
import React from 'react'
import jsPDF from 'jspdf';
import { useSelector } from 'react-redux';
import SquadControlLogoWhite from '../../assets/SquadControlWhite.png'
import LogoSelector from '../../services/logoSelector';
import { useEffect } from 'react';
import { useState } from 'react';
import boksmartLogo from '../../assets/boksmart_logo-removebg-preview.png'

function PrintTeamSelection(props) {

    const schoolName = useSelector((state) => state.user.schoolName)
    const ageGroup = useSelector((state) => state.user.profile.ageGroup)
    // console.log('props received: ', props)
    // console.log('players  received: ', props.data.players)
    // console.log('teams  received: ', props.data.teams)
    const [combinedData, setCombinedData] = useState([]);

    const teams = props?.data?.teams?.filter(team => team.id !== 999)

    //Old UseEffect
    useEffect(() => {
        // Combine teams and players using player ids
        const newData = teams.map((team) => ({
            ...team,
            players: team.players.map((playerId) =>
                props.data.players.find((player) => player.id === playerId)
            ),
        }));
        setCombinedData(newData);

    }, [props]);


    useEffect(() => {
        // console.log('Combined Data: ', combinedData)
    }, [combinedData]);


    // Helper function to get the margin for each table based on its index
    function getTableMargin(index) {
        switch (index) {
            case 0:
                return { right: 140 };
            case 1:
                return { left: 78, right: 78 };
            case 2:
                return { left: 140 };
            default:
                return {};
        }
    }

    //modified new one
    // async function printSelection() {
    //     console.log('Combined: ', combinedData);
    //     var doc = new jsPDF();

    //     // Define the position and size of the logo
    //     const logoX = 10;
    //     const logoY = 10;
    //     const logoWidth = 50;
    //     const logoHeight = 50;

    //     const helperX = 78;
    //     const headerX = 73;
    //     const headerY = logoY + logoHeight + 10;

    //     const SCLogoWidth = 70;
    //     const SCLogoHeight = 80;

    //     const mySchoolLogo = await LogoSelector(schoolName);
    //     doc.addImage(mySchoolLogo, 'PNG', 10, 10, logoWidth, logoHeight);

    //     // Add Extra data
    //     doc.setFontSize(12);
    //     doc.text(`${schoolName}`, helperX, 30);
    //     doc.text(`u/${ageGroup}`, helperX + 20, 40);

    //     doc.addImage(SquadControlLogoWhite, 'PNG', 135, 0, SCLogoWidth, SCLogoHeight);

    //     doc.setFontSize(15);
    //     doc.text(`Team Selection`, headerX + 10, 55);

    //     var pageNumber = doc.internal.getNumberOfPages();
    //     console.log('Page nr: ', pageNumber);


    //     let currentX = 33; // Initial X-coordinate for the first table
    //     let spaceBetweenTables = 62; // Adjust as needed
    //     let maxLastPlayerY = 0; // Track the maximum lastPlayerY

    //     // Iterate over combinedData to create a table for each team
    //     combinedData.forEach((team, index) => {
    //         // Add team name
    //         doc.setFontSize(12);
    //         doc.text(`${team.name}`, currentX - 2, 70);

    //         // Add table for the current team's players
    //         const playerTable = doc.autoTable({
    //             columns: [
    //                 { dataKey: 'Nr', header: '#' },
    //                 { dataKey: 'player', header: 'Player' },
    //             ],
    //             body: bodyRowsForTeam(team.players),
    //             startY: 75,
    //             showHead: 'firstPage',
    //             styles: { overflow: 'hidden' },
    //             margin: getTableMargin(index),
    //         });

    //         // Get the last player's y-coordinate
    //         const lastPlayerY = playerTable.lastAutoTable.finalY;

    //         // Update maxLastPlayerY if the current team has a larger value
    //         maxLastPlayerY = Math.max(maxLastPlayerY, lastPlayerY);

    //         currentX += spaceBetweenTables; // Move to the next X-coordinate for the next table
    //     });

    //     // Use the maximum lastPlayerY for setting startY for all "Impact Players" columns
    //     currentX = 33; // Reset X-coordinate for iterating again
    //     combinedData.forEach((team, index) => {
    //         // Add table for the current team's reserve players (Impact Players)
    //         doc.autoTable({
    //             columns: [
    //                 { dataKey: 'Nr', header: '#' },
    //                 { dataKey: 'player', header: 'Impact Players' },
    //             ],
    //             body: bodyRowsForImpactPlayers(team.reservePlayers),
    //             startY: maxLastPlayerY + 10, // Use the maximum lastPlayerY
    //             showHead: 'firstPage',
    //             styles: { overflow: 'hidden' },
    //             margin: getTableMargin(index),
    //         });

    //         currentX += spaceBetweenTables; // Move to the next X-coordinate for the next table
    //     });

    //     doc.setFont('helvetica', 'normal');
    //     doc.setFontSize(10);
    //     doc.text(`Copyright © Squad Control`, headerX + 10, doc.internal.pageSize.height - 10);


    //     const date = new Date()

    //     doc.save(`Team Selection ${date.toDateString()}.pdf`);
    // }

    async function printSelection() {
        console.log('Combined: ', combinedData);
        var doc = new jsPDF();

        // Define the position and size of the logo
        const logoX = 10;
        const logoY = 10;
        const logoWidth = 50;
        const logoHeight = 50;

        const helperX = 78;
        const headerX = 73;
        const headerY = logoY + logoHeight + 10;

        const SCLogoWidth = 70;
        const SCLogoHeight = 80;

        const mySchoolLogo = await LogoSelector(schoolName);
        doc.addImage(mySchoolLogo, 'PNG', 10, 10, logoWidth, logoHeight);

        // Add Extra data
        doc.setFontSize(12);
        doc.text(`${schoolName}`, helperX, 30);
        doc.text(`u/${ageGroup}`, helperX + 20, 40);

        doc.addImage(SquadControlLogoWhite, 'PNG', 135, 0, SCLogoWidth, SCLogoHeight);

        doc.setFontSize(15);
        doc.text(`Team Selection`, headerX + 10, 55);

        let currentX = 33; // Initial X-coordinate for the first table
        let spaceBetweenTables = 62; // Adjust as needed
        let maxLastPlayerY = 0; // Track the maximum lastPlayerY

        let currentTeam =
            // Iterate over combinedData to create a table for each team
            combinedData.forEach((team, index) => {
                if (index > 2) {
                    doc.addPage(); // If the index is more than 2, put the team on a new page
                    currentX = 33; // Reset X-coordinate for the first table on new page

                }

                // Add team name
                doc.setFontSize(12);
                doc.text(`${team.name}`, currentX - 2, 70);

                // Add table for the current team's players
                const playerTable = doc.autoTable({
                    columns: [
                        { dataKey: 'Nr', header: '#' },
                        { dataKey: 'player', header: 'Player' },
                    ],
                    body: bodyRowsForTeam(team.players),
                    startY: 75,
                    showHead: 'firstPage',
                    styles: { overflow: 'hidden' },
                    margin: getTableMargin(index),
                });

                // Get the last player's y-coordinate
                const lastPlayerY = playerTable.lastAutoTable.finalY;

                // Update maxLastPlayerY if the current team has a larger value
                maxLastPlayerY = Math.max(maxLastPlayerY, lastPlayerY);

                // Move to the next X-coordinate for the next table
                currentX += spaceBetweenTables;
            });

        // Use the maximum lastPlayerY for setting startY for all "Impact Players" columns
        currentX = 33; // Reset X-coordinate for iterating again
        combinedData.forEach((team, index) => {
            // Add table for the current team's reserve players (Impact Players)
            doc.autoTable({
                columns: [
                    { dataKey: 'Nr', header: '#' },
                    { dataKey: 'player', header: 'Impact Players' },
                ],
                body: bodyRowsForImpactPlayers(team.reservePlayers),
                startY: maxLastPlayerY + 10, // Use the maximum lastPlayerY
                showHead: 'firstPage',
                styles: { overflow: 'hidden' },
                margin: getTableMargin(index),
            });

            currentX += spaceBetweenTables; // Move to the next X-coordinate for the next table
        });

        // Save the PDF
        doc.save(`Team Selection.pdf`);
    }



    // Helper function to generate body rows for a team
    function bodyRowsForTeam(players) {
        return players.map((player, playerIndex) => ({
            Nr: playerIndex + 1,
            player: `${player.firstName} ${player.lastName}`,
        }));
    }

    // Helper function to generate body rows for "Impact Players" column
    // function bodyRowsForImpactPlayers(players) {
    //     // Start numbering from 16
    //     let startingNumber = 16;
    //     return players.map((player, playerIndex) => ({
    //         Nr: startingNumber + playerIndex,
    //         player: `${player.firstName} ${player.lastName}`,
    //     }));
    // }

    //New one where 17 is first then 16
    // Helper function to generate body rows for "Impact Players" column
    function bodyRowsForImpactPlayers(players) {
        // Start numbering from 17 for the first player
        let startingNumber = 17;

        // Define the array to hold the sequence of numbers
        let numbers = [startingNumber, 16];

        // Populate the array with the numbers 18 to 23
        for (let i = 18; i <= 23; i++) {
            numbers.push(i);
        }

        // Return the mapped array with player names
        return players.map((player, playerIndex) => ({
            Nr: numbers[playerIndex], // Get the appropriate number for each player
            player: `${player.firstName} ${player.lastName}`,
        }));
    }



    const generatePDF = () => {

        props.openMySnackbar();
        return;

        const doc = new jsPDF();

        doc.setFontSize(9);
        // Insert a rectangle at the top with the text "SAR 44.2017"
        const rectangleHeight = 7;
        doc.rect(131, 3, 31, rectangleHeight, 'S'); // 'S' option draws only the border
        doc.text('SAR 44.2017', 137, 8,);


        doc.setFont('helvetica', 'bold'); // Set font to bold
        doc.setFontSize(10);

        // Set text color to a color slightly darker than MUI default primary color
        //      doc.setTextColor(33, 120, 300); // MUI default primary color: RGB(33, 150, 243)


        doc.setTextColor(20, 100, 180); // Darker blue with a hint of gray


        // Add content to the PDF with bold text
        doc.text('SOUTH AFRICAN RUGBY UNION - TEAM SHEET', 65, 15);

        // Reset font to normal if needed
        doc.setFont('helvetica', 'bold');
        doc.addImage(boksmartLogo, 'PNG', 90, 15, 30, 15);
        doc.setTextColor(0, 0, 0);

        // Reset text color to black if needed

        doc.setFont('helvetica', 'bolditalic');
        doc.setFontSize(7);
        doc.text('This team sheet must be completed by the Team Manager and handed to the Officiating Referee at least 1 hour (sixty minutes) before the start (kick off', 20, 30);
        doc.text('time) of the match concerned – Coach 1 (Head coach) and Coach 2 (Assistant Coach) are for the same team; both teams have to submit team sheets!)', 20, 34);

        doc.setFont('helvetica', 'bold');
        doc.setFontSize(9);
        doc.text('COMPETITION/AGE GRADE:', 22, 42);
        doc.text('TEAM: OPPOSING TEAM:', 22, 52);
        doc.text('TEAM COACH 1 (name): TEAM COACH 2 (name):', 22, 62);
        doc.text('BokSmart (BS) No. COACH 1: BS‐ BokSmart (BS) No. COACH 2: BS‐', 22, 72);
        doc.text('MATCH REFEREE (name): BokSmart (BS) No. Referee: BS‐', 22, 82);
        doc.text('Assistant Referee 1 ( where appl .): BS‐ Assistant Referee 2 ( where appl. ): BS‐', 22, 92);
        doc.text('VENUE:', 22, 102);
        doc.text('DAY: TIME: DATE:', 22, 112);



        // Position to start the table
        const startX = 20;
        const startY = 120;

        // Column names
        const columnNames = [
            'TEAM LIST', 'Initials & Surname', 'Name', 'Player Reg #', 'Suspected/Confirmed',
            'DOB (& Age)', 'Concussion?', 'DOB = Date of Birth\nOnly note if YES DD / MM / YYYY (YRS)'
        ];

        // Add the player positions
        const playerPositions = [
            ['15', 'Full back'], ['14', 'Right wing'], ['13', 'Right centre'], ['12', 'Left centre'], ['11', 'Left wing'],
            ['10', 'Fly half'], ['9', 'Scrum half'], ['8', 'Number eight'], ['7', 'Right flanker'], ['6', 'Left flanker'],
            ['5', 'Right lock'], ['4', 'Left lock'], ['3', 'Tight head prop'], ['2', 'Hooker'], ['1', 'Loose head prop']
        ];

        // Reserve positions
        const replacementPositions = ['16 Hooker', '17 Prop', '18', '19', '20', '21', '22'];

        // Combine column names, player positions, and reserve positions
        const tableData = [columnNames, ...playerPositions.map(position => [position, '', '', '', '', '', '', ''])];

        // Add space for replacements
        tableData.push(['', '', '', '', '', '', '', ''], ...replacementPositions.map(position => [position, '', '', '', '', '', '', '']));

        // Options for the table
        const tableOptions = {
            startY: startY,
            styles: { halign: 'center' },
            theme: 'grid',
            columnStyles: {
                0: { cellWidth: 50 }, // TEAM LIST
                1: { cellWidth: 60 }, // Initials & Surname
                2: { cellWidth: 60 }, // Name
                3: { cellWidth: 40 }, // Player Reg #
                4: { cellWidth: 50 }, // Suspected/Confirmed
                5: { cellWidth: 60 }, // DOB (& Age)
                6: { cellWidth: 40 }, // Concussion?
                7: { cellWidth: 80 }, // DOB = Date of Birth
            },
        };

        // Create the table
        doc.autoTable({
            body: tableData,
            ...tableOptions,
        });





        // // TEAM LIST
        // doc.text('TEAM LIST Initials & Surname Name Player Reg # Suspected/Confirmed DOB (& Age)', 20, 140);
        // doc.text('Concussion? DOB = Date of Birth', 20, 150);
        // doc.text('Only note if YES DD / MM/ YYYY (YRS)', 20, 160);

        // // Add the player positions
        // const playerPositions = [
        //     '15 Full back', '14 Right wing', '13 Right centre', '12 Left centre', '11 Left wing',
        //     '10 Fly half', '9 Scrum half', '8 Number eight', '7 Right flanker', '6 Left flanker',
        //     '5 Right lock', '4 Left lock', '3 Tight head prop', '2 Hooker', '1 Loose head prop'
        // ];

        let yPos = 170;
        // playerPositions.forEach((position, index) => {
        //     doc.text(`${position} / / ( )`, 20, yPos + index * 10);
        // });
        // // REPLACEMENTS
        // doc.text('REPLACEMENTS (Manager to provide positions; bear in mind that there has to be at least one (1) prop and one (1) hooker on the bench, but for U19', 20, yPos + playerPositions.length * 10 + 10);
        // doc.text('teams and younger an additional prop on the bench is compulsory for squads of 22 players)', 20, yPos + playerPositions.length * 10 + 20);

        // const replacementPositions = ['16 Hooker', '17 Prop', '18', '19', '20', '21', '22'];
        // replacementPositions.forEach((position, index) => {
        //     doc.text(`${position} / / ( )`, 20, yPos + playerPositions.length * 10 + 30 + index * 10);
        // });

        // Certification section
        doc.text('I hereby certify that the above information is correct:', 20, yPos + playerPositions.length * 10 + 80);
        doc.text('Team Manager: Mobile Number:', 20, yPos + playerPositions.length * 10 + 90);
        doc.text('Medical Doctor:', 20, yPos + playerPositions.length * 10 + 100);
        doc.text('Please Print HPCSA Number: MD-', 20, yPos + playerPositions.length * 10 + 110);
        doc.text('Physiotherapist:', 20, yPos + playerPositions.length * 10 + 120);
        doc.text('Please Print HPCSA Number: PT-', 20, yPos + playerPositions.length * 10 + 130);
        doc.text('Date:', 20, yPos + playerPositions.length * 10 + 140);
        doc.text('Please Print Signed by Team Manager:', 20, yPos + playerPositions.length * 10 + 150);

        // Save the PDF
        doc.save('rugby_team_sheet.pdf');
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'starts' }}>
            <Button variant='contained' onClick={() => { printSelection(); }}>
                Print Teams
            </Button>

            <Button variant='contained' onClick={() => { generatePDF(); }} style={{ marginLeft: '5px' }}>
                Print BokSmart Teams
            </Button>
        </div>


    )
}

export default PrintTeamSelection
