import React from 'react'
import waterkloofLogo from '../assets/kloof.jpeg'
import kloofrb from '../assets/kloofRB.png'
import maraisviljoen from '../assets/maraisviljoen.jpeg'
import maraisviljoenRB from '../assets/maraisviljoenRB.png'
import monnaslogo from '../assets/Monnas Logo.jpeg'
import monnaslogoRB from '../assets/MonnasRB.png'
import affiesWapen from '../assets/Affies_wapen.jpg'
import AffiesLogo from '../assets/affies-logo.png'
import AffiesLogoRB from '../assets/AffiesLogoRB.png'
import EGJansenLogo from '../assets/EGJansenLogo.png'
import EGJansenLogoRB from '../assets/EGJansenLogoRB.png'
import MenloLogo from '../assets/menlo.jpeg'
import menloLogoRB from '../assets/menlo-RB.png'
import SquadControlLogo from '../assets/SquadControlWhite.png'
import SquadControlWhiteRB from '../assets/SquadControlWhiteRB.png'
import BenVorster from '../assets/Ben Vorster.jpeg'
import Boland from '../assets/Boland.jpeg'
import BolandRB from '../assets/BolandRB.png'
import Brandwag from '../assets/Brandwag.png'
import Centurion from '../assets/Centurion.jpeg'
import CenturionRB from '../assets/CenturionRB.png'
import Eldoraigne from '../assets/Eldoraigne.jpeg'
import EldoraigneRB from '../assets/EldoraigneRB.png'
import Ermelo from '../assets/Ermelo.png'
import FrikkieMeyer from '../assets/Frikkie Meyer.png'
import FrikkieMeyerRB from '../assets/Frikkie_MeyerRB.png'
import Garsfontein from '../assets/garsfontein.png'
import Grey from '../assets/Grey College.jpeg'
import GreyRB from '../assets/Grey College RB.png'
import Helpmekaar from '../assets/HelpMekaar.png'
import Rustenburg from '../assets/Rustenburg-removebg-preview.png'
import JohnVorster from '../assets/HTS John Vorster.png'
import Middelburg from '../assets/HTS Middelburg.png'
import Kempton from '../assets/Kempton Park.png'
import Marlow from '../assets/Marlow Landbou.png'
import MarlowRB from '../assets/Marlow_LandbouRB.png'
import Merensky from '../assets/Merensky.png'
import Nelspruit from '../assets/Nelspruit.png'

import Noordheuwel from '../assets/Noordheuwel.jpeg'
import NoordheuwelRB from '../assets/Noordheuwel-RB.png'
import OosMoot from '../assets/oos moot.png'
import OuteniquaRB from '../assets/Outeniqua-RB.png'
import PaarlBoysHighSchool from "../assets/Paarl Boys' High School.png"
import PaarlGim from '../assets/paarlGim.jpg'
import PaarlGimRB from '../assets/paarlGim-RB.png'
import PHS from '../assets/PHS.png'
import PHSRB from '../assets/PHS-RB.png'
import PretoriaBoysHigh from '../assets/PBH-RB.png'

//import PietRetief from '../assets/Piet Retief.png'
import PietRetiefRB from '../assets/Piet_Retief-removebg-preview.png'

import Transvalia from '../assets/Transvalia.png'
import TransvaliaRB from '../assets/Transvalia-RB.png'
import Wonderboom from '../assets/Wonderboom.png'
import Zwartkop from '../assets/Zwartkop.png'

import GarsfonteinLogo from '../assets/garsfonteinLogo.png'
import Framesby from '../assets/framesbyLogo.png'
import Selborne from '../assets/selbourneLogo.png'

async function LogoSelector(schoolName) {
    console.log('Opponent received in function: ', schoolName)
    switch (true) {

        case schoolName.includes('Afrikaanse Hoër Seunskool'):
            return AffiesLogoRB
        case schoolName.includes('Hoërskool Ben Vorster'):
            return BenVorster
        case schoolName.includes('Boland Landbou'):
            return BolandRB
        case schoolName.includes('Hoërskool Brandwag'):
            return Brandwag
        case schoolName.includes('Hoërskool Centurion'):
            return CenturionRB
        case schoolName.includes('Hoërskool Dr. EG Jansen'):
            //  return EGJansenLogo
            return EGJansenLogoRB
        case schoolName.includes('Hoërskool Dr. E.G. Jansen'):
            // return EGJansenLogo
            return EGJansenLogoRB
        case schoolName.includes('Hoërskool Eldoraigne'):
            return EldoraigneRB
        case schoolName.includes('Hoërskool Ermelo'):
            return Ermelo
        case schoolName.includes('Hoërskool Frikkie Meyer'):
            return FrikkieMeyerRB
        case schoolName.includes('Grey College'):
            // return Grey
            return GreyRB
        case schoolName.includes('Hoërskool Helpmekaar'):
            return Helpmekaar
        case schoolName.includes('Hts John Vorster'):
            return JohnVorster
        case schoolName.includes('Hts Middelburg'):
            return Middelburg
        case schoolName.includes('Hoërskool Kempton Park'):
            return Kempton
        case schoolName.includes('Hoërskool Marais Viljoen'):
            return maraisviljoenRB
        case schoolName.includes('Hoërskool Marlow'):
            return MarlowRB
        case schoolName.includes('Hoërskool Menlo Park'):
            return menloLogoRB
        case schoolName.includes('Hoërskool Merensky'):
            return Merensky
        case schoolName.includes('Hoërskool Monument Park'):
            return monnaslogoRB
        case schoolName.includes('Hoërskool Nelspruit'):
            return Nelspruit
        case schoolName.includes('Hoërskool Noordheuwel'):
            return NoordheuwelRB
        case schoolName.includes('Hoërskool Oos Moot'):
            return OosMoot
        case schoolName.includes('Hoërskool Outeniqua'):
            return OuteniquaRB
        case schoolName.includes('Paarl Boys High'):
            return PaarlBoysHighSchool
        case schoolName.includes('Paarl Gimnasium'):
            return PaarlGimRB
        case schoolName.includes('Pietersburg Hoërskool'):
            return PHSRB
        case schoolName.includes('Hoërskool Piet Retief'):
            return PietRetiefRB
        case schoolName.includes('Hoërskool Framesby'):
            return Framesby
        case schoolName.includes('Selborne College'):
            return Selborne
        case schoolName.includes('Hoërskool Transvalia'):
            return TransvaliaRB
        case schoolName.includes('Hoërskool Waterkloof'):
            //  return waterkloofLogo
            return kloofrb
        case schoolName.includes('Hoërskool Wonderboom'):
            return Wonderboom
        case schoolName.includes('Hoërskool Zwartkop'):
            return Zwartkop
        case schoolName.includes('Hoërskool Garsfontein'):
            return GarsfonteinLogo
        case schoolName.includes('Pretoria Boys High'):
            return PretoriaBoysHigh
            case schoolName.includes('Hoërskool Rustenburg'):
            return Rustenburg
        default:
            return SquadControlWhiteRB
    }
}

export default LogoSelector


////Just new Naming Structure
// import React from 'react'
// import waterkloofLogo from '../assets/kloof.jpeg'
// import kloofrb from '../assets/kloofRB.png'
// import maraisviljoen from '../assets/maraisviljoen.jpeg'
// import maraisviljoenRB from '../assets/maraisviljoenRB.png'
// import monnaslogo from '../assets/Monnas Logo.jpeg'
// import monnaslogoRB from '../assets/MonnasRB.png'
// import affiesWapen from '../assets/Affies_wapen.jpg'
// import AffiesLogo from '../assets/affies-logo.png'
// import AffiesLogoRB from '../assets/AffiesLogoRB.png'
// import EGJansenLogo from '../assets/EGJansenLogo.png'
// import EGJansenLogoRB from '../assets/EGJansenLogoRB.png'
// import MenloLogo from '../assets/menlo.jpeg'
// import menloLogoRB from '../assets/menlo-RB.png'
// import SquadControlLogo from '../assets/SquadControlWhite.png'
// import SquadControlWhiteRB from '../assets/SquadControlWhiteRB.png'
// import BenVorster from '../assets/Ben Vorster.jpeg'
// import Boland from '../assets/Boland.jpeg'
// import BolandRB from '../assets/BolandRB.png'
// import Brandwag from '../assets/Brandwag.png'
// import Centurion from '../assets/Centurion.jpeg'
// import CenturionRB from '../assets/CenturionRB.png'
// import Eldoraigne from '../assets/Eldoraigne.jpeg'
// import EldoraigneRB from '../assets/EldoraigneRB.png'
// import Ermelo from '../assets/Ermelo.png'
// import FrikkieMeyer from '../assets/Frikkie Meyer.png'
// import FrikkieMeyerRB from '../assets/Frikkie_MeyerRB.png'
// import Garsfontein from '../assets/garsfontein.png'
// import Grey from '../assets/Grey College.jpeg'
// import GreyRB from '../assets/Grey College RB.png'
// import Helpmekaar from '../assets/HelpMekaar.png'
// import Rustenburg from '../assets/Rustenburg-removebg-preview.png'
// import JohnVorster from '../assets/HTS John Vorster.png'
// import Middelburg from '../assets/HTS Middelburg.png'
// import Kempton from '../assets/Kempton Park.png'
// import Marlow from '../assets/Marlow Landbou.png'
// import MarlowRB from '../assets/Marlow_LandbouRB.png'
// import Merensky from '../assets/Merensky.png'
// import Nelspruit from '../assets/Nelspruit.png'

// import Noordheuwel from '../assets/Noordheuwel.jpeg'
// import NoordheuwelRB from '../assets/Noordheuwel-RB.png'
// import OosMoot from '../assets/oos moot.png'
// import OuteniquaRB from '../assets/Outeniqua-RB.png'
// import PaarlBoysHighSchool from "../assets/Paarl Boys' High School.png"
// import PaarlGim from '../assets/paarlGim.jpg'
// import PaarlGimRB from '../assets/paarlGim-RB.png'
// import PHS from '../assets/PHS.png'
// import PHSRB from '../assets/PHS-RB.png'
// import PretoriaBoysHigh from '../assets/PBH-RB.png'

// //import PietRetief from '../assets/Piet Retief.png'
// import PietRetiefRB from '../assets/Piet_Retief-removebg-preview.png'

// import Transvalia from '../assets/Transvalia.png'
// import TransvaliaRB from '../assets/Transvalia-RB.png'
// import Wonderboom from '../assets/Wonderboom.png'
// import Zwartkop from '../assets/Zwartkop.png'

// import GarsfonteinLogo from '../assets/garsfonteinLogo.png'
// import Framesby from '../assets/framesbyLogo.png'
// import Selborne from '../assets/selbourneLogo.png'

// async function LogoSelector(schoolName) {
//     // console.log('Opponent received in function: ', schoolName)
//     switch (true) {

//         case schoolName.includes('Affies'):
//             return AffiesLogoRB
//         case schoolName.includes('Ben Vorster'):
//             return BenVorster
//         case schoolName.includes('Boland Landbou'):
//             return BolandRB
//         case schoolName.includes('Brandwag'):
//             return Brandwag
//         case schoolName.includes('Centurion'):
//             return CenturionRB
//         case schoolName.includes('Dr. EG Jansen'):
//             //  return EGJansenLogo
//             return EGJansenLogoRB
//         case schoolName.includes('Eldoraigne'):
//             return EldoraigneRB
//         case schoolName.includes('Ermelo'):
//             return Ermelo
//         case schoolName.includes('Frikkie Meyer'):
//             return FrikkieMeyerRB
//         case schoolName.includes('Grey College'):
//             // return Grey
//             return GreyRB
//         case schoolName.includes('Helpmekaar'):
//             return Helpmekaar
//         case schoolName.includes('John Vorster'):
//             return JohnVorster
//         case schoolName.includes('Middelburg'):
//             return Middelburg
//         case schoolName.includes('Kempton Park'):
//             return Kempton
//         case schoolName.includes('Marais Viljoen'):
//             return maraisviljoenRB
//         case schoolName.includes('Marlow'):
//             return MarlowRB
//         case schoolName.includes('Menlo Park'):
//             return menloLogoRB
//         case schoolName.includes('Merensky'):
//             return Merensky
//         case schoolName.includes('Monument'):
//             return monnaslogoRB
//         case schoolName.includes('Nelspruit'):
//             return Nelspruit
//         case schoolName.includes('Noordheuwel'):
//             return NoordheuwelRB
//         case schoolName.includes('Oos Moot'):
//             return OosMoot
//         case schoolName.includes('Outeniqua'):
//             return OuteniquaRB
//         case schoolName.includes('Paarl Boys High'):
//             return PaarlBoysHighSchool
//         case schoolName.includes('Paarl Gimnasium'):
//             return PaarlGimRB
//         case schoolName.includes('Pietersburg'):
//             return PHSRB
//         case schoolName.includes('Piet Retief'):
//             return PietRetiefRB
//         case schoolName.includes('Framesby'):
//             return Framesby
//         case schoolName.includes('Selborne College'):
//             return Selborne
//         case schoolName.includes('Transvalia'):
//             return TransvaliaRB
//         case schoolName.includes('Waterkloof'):
//             //  return waterkloofLogo
//             return kloofrb
//         case schoolName.includes('Wonderboom'):
//             return Wonderboom
//         case schoolName.includes('Zwartkop'):
//             return Zwartkop
//         case schoolName.includes('Garsfontein'):
//             return GarsfonteinLogo
//         case schoolName.includes('Pretoria Boys High'):
//             return PretoriaBoysHigh
//         case schoolName.includes('Rustenburg'):
//             return Rustenburg
//         default:
//             return SquadControlWhiteRB
//     }
// }

// export default LogoSelector



// Schools from the updated list that do not have logos:
// Oakdale
// KES (King Edward VII School)
// Jeppe
// DHS (Durban High School)
// Rondebosch
// Stellenberg
// Westville
// Maritzburg College
// Grey High School (different from Grey College)
// Wynberg BH (Wynberg Boys' High)
// Michaelhouse
// SACS (South African College Schools)
// Hilton College
// Queens College
// Diamantveld
// Nico Malan
// St Johns College
// Kingswood College
// St Charles College
// Voortrekker Beth (Voortrekker High)
// Pearson
// St Andrews College
// Durbanville
// Milnerton
// Glenwood
// Hudson Park
// Wesvalia
// St Stithians
// Middelburg HS (different from HTS Middelburg)
// Charlie Hofmeyr
// Worcester Gym