import * as React from 'react';
import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from './Navigator';
import Header from './Header';
import theme from './theme';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import Approval from '../userApproval/approval';
import Copyright from '../../layouts/copyRight';



const drawerWidth = 256;

export default function Paperbase({ children }) {
  // const approved = useSelector((state) => state.user.approved)
  const profile = useSelector((state) => state.user.profile)
  let approved
  profile !== undefined ? approved = profile.approved : approved = ''
  console.log('Approved is: ', approved)
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    console.log('Approved in useEffect is: ', approved)
  }, [approved])


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      {
        approved === 'True' ?

          <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
              <CssBaseline />
              <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              >
                {isSmUp ? null : (
                  <Navigator
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                  />
                )}

                <Navigator
                  PaperProps={{ style: { width: drawerWidth } }}
                  sx={{ display: { sm: 'block', xs: 'none' } }}
                />
              </Box>
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <Header onDrawerToggle={handleDrawerToggle} />
                <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>

                  {children}
                </Box>
                <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
                  <Copyright />
                </Box>
              </Box>
            </Box>
          </ThemeProvider>
          :
          <>
            {
              //Checking for profile to solve the bug: 
              //When user logs out approval form used to show up for a brief moment
              //This is the fix to rather check for profile first before displaying component
              profile ?
                <Approval />
                :
                <></>
            }
          </>
      }
    </div>
  );
}