import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from '../dashboard/Content/chart.js';
import Deposits from '../dashboard/Content/deposit.js';
import Orders from '../dashboard/Content/Order.js';
import StatGrid from '../stats/statGrid.js';
import { useDispatch, useSelector } from 'react-redux';
import FutureSessions from '../session/FutureSessions.js';
import NextGame from '../season/nextGame.js';
import { useEffect } from 'react';
import { calendarRequests, gameRequests, gamedayStats, seasonRequests, seasonStats, teamRequests, userRequests } from '../../services/api-requests.js';
import { useState } from 'react';
import Loader from '../../layouts/loader.js';
import { setAvailableTeams } from '../team/newTeam/teamSlice.js';
import { setBackendSeason } from '../season/seasonSlice.js';
import { setSchoolName } from '../user/userSlice.js';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Squad Control
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const drawerWidth = 240;

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   transition: theme.transitions.create(['width', 'margin'], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   ...(open && {
//     marginLeft: drawerWidth,
//     width: `calc(100% - ${drawerWidth}px)`,
//     transition: theme.transitions.create(['width', 'margin'], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//   }),
// }));

// const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     '& .MuiDrawer-paper': {
//       position: 'relative',
//       whiteSpace: 'nowrap',
//       width: drawerWidth,
//       transition: theme.transitions.create('width', {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       boxSizing: 'border-box',
//       ...(!open && {
//         overflowX: 'hidden',
//         transition: theme.transitions.create('width', {
//           easing: theme.transitions.easing.sharp,
//           duration: theme.transitions.duration.leavingScreen,
//         }),
//         width: theme.spacing(7),
//         [theme.breakpoints.up('sm')]: {
//           width: theme.spacing(9),
//         },
//       }),
//     },
//   }),
// );

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Dashboard() {
  const futureSessions = useSelector((state) => state.session.futureSessions)
  const seasonGames = useSelector((state) => state.season.games)
  const [open, setOpen] = React.useState(true);
  const [closestGame, setClosestGame] = useState({})
  const [closestEvent, setClosestEvent] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [seasonLoading, setSeasonLoading] = useState(false);
  const [closestEventLoading, setClosestEventLoading] = useState(false);
  const [closestGameLoading, setClosestGameLoading] = useState(false);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [teamId, setTeamId] = useState(0);
  const [gamesSummary, setGamesSummary] = useState([])
  const [percentageInfo2, setPercentageInfo2] = useState('Season not started yet')

  const schoolId = parseInt(useSelector((state) => state.user.profile.school))
  const ageGroup = parseInt(useSelector((state) => state.user.profile.ageGroup))
  const stateTeams = useSelector((state) => state.team.availableTeams)
  const schoolName = useSelector((state) => state.user.schoolName)
  const availableTeams = useSelector((state) => state.team.availableTeams)

  const dispatch = useDispatch();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);

  //     console.log('AvailableTeams: ', availableTeams)
  //     if (availableTeams.length !== 0) {

  //       const ATeam = availableTeams[0];
  //       const ATeamId = ATeam.id;

  //       console.log('Searching with this id: ', ATeamId)
  //       setTeamId(ATeamId)

  //       if (ATeamId !== 0) {
  //         await fetchSeason(ATeamId).then(() => {
  //           fetchClosestGame(ATeamId)
  //           fetchGameDaySummary(ATeamId)
  //           fetchClosestEvent();
  //         })
  //           .finally(() => {
  //             setIsLoading(false);
  //           });
  //       }
  //       else {
  //         setIsLoading(false);
  //       }
  //     }
  //     else {
  //       await fetchTeams().then(() => {
  //         fetchClosestGame()
  //         fetchGameDaySummary()
  //         fetchSeason();
  //       })
  //         .finally(() => {
  //           setIsLoading(false);
  //         });
  //     }
  //   };
  //   if (!schoolName) fetchSchoolName();

  //   fetchData();
  // }, [schoolId,]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      if (availableTeams.length !== 0) {
        const ATeam = availableTeams[0];
        const ATeamId = ATeam.id;

        setTeamId(ATeamId);

        if (ATeamId !== 0 || ATeamId !== undefined) {
          await fetchSeason(ATeamId).then(() => {
            fetchTeams()
            fetchClosestGame(ATeamId);
            fetchGameDaySummary(ATeamId);
            fetchClosestEvent();
          }).finally(() => {
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      } else {
        // Fetch teams and get ATeamId
        const ATeamId = await fetchTeams();

        if (ATeamId !== 0 || ATeamId !== undefined) {
          await fetchSeason(ATeamId).then(() => {
            fetchClosestGame(ATeamId);
            fetchGameDaySummary(ATeamId);
            fetchClosestEvent();
          }).finally(() => {
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
        }
      }
    };

    if (!schoolName) {
      fetchSchoolName();
    }

    fetchData();
  }, [schoolId]);


  // const fetchTeams = async () => {

  //   setIsLoading(true);
  //   setTeamsLoading(true);
  //   try {
  //     const response = await teamRequests.getTeams(schoolId, ageGroup);
  //     // const response = await teamRequests.getTeams(2, 14);

  //     console.log('Teams received: ', response.data)

  //     if (response.status === 200) {
  //       // stateTeams.length === 0 ?
  //       dispatch(setAvailableTeams(response.data))
  //       // :
  //       // console.log('StateTeams already populated')


  //     }
  //     else {
  //       //    console.log('In the else');

  //       setTeamsLoading(false);
  //     }

  //   } catch (error) {
  //     console.error('Error fetching Teams:', error);
  //   } finally {
  //     setIsLoading(false);
  //     setTeamsLoading(false);
  //   }
  // };

  const fetchTeams = async () => {
    setIsLoading(true);
    setTeamsLoading(true);
    let ATeamId = 0;

    try {
      const response = await teamRequests.getTeams(schoolId, ageGroup);

      if (response.status === 200) {
        console.log('RESPONSE FROM DASHBOARD TEAMS: ', response.data
        )
        dispatch(setAvailableTeams(response.data));
        ATeamId = response.data[0]?.id || 0; // Get the A Team Id if available
      } else {
        setTeamsLoading(false);
      }
    } catch (error) {
      console.error('Error fetching Teams:', error);
    } finally {
      setIsLoading(false);
      setTeamsLoading(false);
    }

    return ATeamId;
  };

  const fetchSeason = async (teamId) => {
    setSeasonLoading(true)
    setIsLoading(true);
    try {
      const response = await seasonRequests.getSeason(teamId);
      // const response = await seasonRequests.getSeason(teamid);

      console.log('Season received in request in Dashboard: ', response.data)

      if (response.status === 200) {
        dispatch(setBackendSeason(response.data));


      } else if (response.status === 400) {
        console.log('Season does not exist');

      } else {
        console.log('In the else');

      }

    } catch (error) {
      console.error('Error fetching season:', error);
    } finally {
      setIsLoading(false);
      setSeasonLoading(false)
    }
  };

  const fetchClosestEvent = async () => {
    setClosestEventLoading(true);

    try {
      console.log('SchoolID : ', schoolId, ' age: ', ageGroup)
      const response = await calendarRequests.GetEvents(schoolId, ageGroup);

      if (response.status === 200) {
        console.log('Events received: ', response.data);

        const currentDate = new Date();

        // Filter events that are in the future or happening today
        const futureEvents = response.data.filter((event) => {
          const eventDate = new Date(event.date);
          return eventDate >= currentDate;
        });

        // Sort events by date in ascending order
        futureEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

        if (futureEvents.length > 0) {
          // The closest event is the first one in the sorted array
          const closestEvent = futureEvents[0];
          console.log('Closest Event:', closestEvent);
          setClosestEvent(closestEvent);
        } else {
          console.log('No Nearby Events');
          setClosestEvent('No Nearby Events');
        }
      } else {
        console.log('Failed to fetch events');
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setClosestEventLoading(false);
    }
  };

  const fetchGameDaySummary = async (teamId) => {
    setIsLoading(true);
    try {
      const response = await seasonStats.GetGamesSummary(teamId);

      console.log('Games Stats Summary Received: ', response.data)
      setGamesSummary(response.data)

      if (response.status === 200) {

        setGamesSummary(response.data)
        setIsLoading(false)
      }
      if (response.status === 404) {
        setIsLoading(false)
      }
      else {
        console.log('Cant get Games Season Stats summary');
        setIsLoading(false)
      }

    } catch (error) {
      console.error('Error fetching Game Stats Summary:', error);
    } finally {
      setIsLoading(false);
      let percentageComplete = 0;
      let percentageInfo2 = "Season not started yet";

      if (gamesSummary && gamesSummary.gamesPlayed !== undefined && gamesSummary.totalGames !== undefined && gamesSummary.totalGames > 0) {
        percentageComplete = Math.round((gamesSummary.gamesPlayed / gamesSummary.totalGames) * 100);

        if (percentageComplete > 0 && percentageComplete <= 100) {
          setPercentageInfo2(`${percentageComplete}% complete`);
        }
        console.log('Percentage complete: ', `${percentageComplete}% complete`)
      }
    }



  }


  const fetchClosestGame = async (teamId) => {

    setClosestGameLoading(true);
    try {
      const response = await gameRequests.getClosestGame(teamId);
      // const response = await teamRequests.getTeams(teamId);

      if (response.status === 200) {
        console.log('Closest Game received: ', response.data)
        setClosestGame(response.data)

      }
      else {
        console.log('In the else');

      }

    } catch (error) {
      console.error('Error fetching closest game:', error);
    } finally {
      setClosestGameLoading(false);
    }
  };

  const fetchSchoolName = async () => {
    try {
      await userRequests.getAllSchools().then((response) => {
        if (response.status === 200) {
          const schoolName = response.data.find((school) => school.id === schoolId)
          console.log('School I want: ', schoolName)
          dispatch(setSchoolName(schoolName.name))
        }
      });
    }
    catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  return (

    <ThemeProvider theme={defaultTheme}>
      {closestGameLoading || closestEventLoading || seasonLoading || teamsLoading || isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CssBaseline />
          {/* <AppBar position="absolute" open={open}>
        </AppBar> */}
          <Box
            component="main"
            sx={{
              backgroundColor: {
                // height: '100vh',
                overflow: 'auto',
              },
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',

              // backgroundColor: (theme) =>
              //   theme.palette.mode === 'light'
              //     ? theme.palette.grey[100]
              //     : theme.palette.grey[900],
              // flexGrow: 1,

            }}
          >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: -1, mb: 0 }}>

              <Grid container spacing={3}>
                <StatGrid size="3"
                  category="Calendar"
                  title="Next Activity"
                  metric={closestEvent.eventName ? closestEvent.eventName : "No Event Yet"}
                  info1={closestEvent.date ? closestEvent.date : "No Date Chosen"}
                  info2=""
                  extraInfo="ME"
                />

                <StatGrid size="3"
                  title="Game progress timeline"
                  metric={gamesSummary.gamesPlayed !== undefined && gamesSummary.totalGames !== undefined ? `${gamesSummary.gamesPlayed}/${gamesSummary.totalGames}` : 0}
                  info1="Games played"
                  info2={percentageInfo2}

                />

                {/* Chart */}
                {/* <Grid item xs={12} md={8} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 240,
                    }}
                  >
                    <Chart />
                  </Paper>
                </Grid> */}
                {/* Recent Deposits */}
                {/* <Grid item xs={12} md={4} lg={3}> */}

                {/* </Grid> */}
                {/* Recent Orders */}
                <Grid item xs={12}>
                  <FutureSessions

                    weeklyFocusPoint={''}
                    sessions={[]}
                    type={'dashboard'}
                    team={""}
                    futureSessions={futureSessions ? futureSessions : []}
                    refreshTeamSessions={''}
                  />
                  {/* <FutureSessions futureSessions={futureSessions} type='dashboard' /> */}
                  <br />

                  <NextGame title="Next Opponent: "
                    metric={closestGame === 'No games found in season' ? "Next Oppontent not set up yet" : closestGame.opponent !== undefined ? closestGame.opponent : 'No Nearby Opponent found'}
                    date={closestGame === 'No games found in season' ? "Get some sessions in before the next game" : closestGame.date !== undefined ? closestGame.date : 'No date'}
                    venue={closestGame === 'No games found in season' ? "" : closestGame.venue !== undefined ? closestGame.venue : 'No Venue'}
                    type={closestGame === 'No games found in season' ? "" : closestGame.type !== undefined ? closestGame.type : 'No Type'} />
                </Grid>
              </Grid>
              {/* <Copyright sx={{ pt: 4 }} /> */}
            </Container>
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}