import { Box, Button, Select, MenuItem } from "@mui/material";
import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from "../dashboard/Content/title";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function StatGrid({ size, category, title, metric, info1, info2, splitTypes, onSelectSplitType, selectedType, extraInfo }) {
  const navigate = useNavigate()

  const [splitsCompleted, setSplitsCompleted] = React.useState(selectedType);

  function handleClick(event) {
    if (category !== undefined) {
      navigate(`/${category}`)
    }
    event.preventDefault();
  }

  // const locationOfT = info1.indexOf('T')
  // console.log('Location of T in Date: ', locationOfT)
  // const newInfo1 = info1.slice(0, locationOfT)
  // console.log('NewDate: ', newInfo1)

  let newInfo1
  let myExtraInfo = false

  if (extraInfo === 'ME') {
    myExtraInfo = true

    const locationOfT = info1.indexOf('T');

    console.log('Location of T in Date: ', locationOfT);

    if (locationOfT !== -1) {
      newInfo1 = info1.slice(0, locationOfT);
      console.log('New Date: ', newInfo1);
    } else {
      newInfo1 = info1
      console.log('T not found in Date');
    }
  }



  //Callback to Parent
  function HandleSplitSelect(event) {
    event.preventDefault();
    setSplitsCompleted(event.target.value)
    onSelectSplitType(event);
  }

  return (
    <Grid item xs={12} md={6} lg={size !== undefined ? 6 : 4} >
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 240,
        }}
      >
        <React.Fragment>
          <Title sx={{ textDecoration: 'underline' }} variant="h4">{title}</Title>
          <Typography component="p" variant="h5">
            {metric}
          </Typography>

          {info1 !== "Needs to be selected" ?
            <Typography color="text.secondary" sx={{ flex: 1, marginTop: 2 }}>
              {myExtraInfo ? newInfo1 : info1}
            </Typography>
            :
            <div style={{ display: "flex", justifyContent: "center", marginTop: 23 }}>

              <Select
                id="demo-simple-select"
                value={splitsCompleted}
                placeholder="Select Split"
                onChange={(event) => { HandleSplitSelect(event) }}
                sx={{ width: '150px', height: '80%', }}
                displayEmpty
                renderValue={
                  (value) => {
                    if (!value) return "None";
                    return splitTypes.find((split) => split.id === value).type;
                  }
                }
              >
                {
                  splitTypes && splitTypes.length > 0 ? (
                    splitTypes.map((split) => (
                      <MenuItem key={split.id} value={split.id}>
                        {split.type ? split.type : "Nothing Received yet"}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">No Split types availabe. Please refresh page</MenuItem>
                  )
                }

              </Select>
            </div>
          }

          <Typography color="text.secondary" sx={{ flex: 1 }}>
            {info2}
          </Typography>
          <div>
            <Link color="primary" href="#" onClick={handleClick}>
              See details
            </Link>
          </div>
        </React.Fragment>
      </Paper>
    </Grid>
  )
};

export default StatGrid

