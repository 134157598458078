import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete, Divider, FormControlLabel, FormGroup, Checkbox, Snackbar } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import 'jspdf-autotable';
import GenerateGameReport from '../gameday/reports/generateGameReport'
import { useNavigate } from 'react-router-dom';
import Title from '../dashboard/Content/title';
import NextGame from './nextGame';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from "@mui/material";
import Container from '@mui/material/Container';
import { setBackendSeason, setChanged, setStateSeasonStarted, setTeamsSeasons } from './seasonSlice';
import { SetupGames } from './setupGames';
import { InSeasonComponent } from './inSeason';
import { CreateSeason } from './createSeason';
import { seasonRequests, teamRequests } from '../../services/api-requests';
import Loader from '../../layouts/loader';
import MuiAlert from '@mui/material/Alert';
import { setAvailableTeams } from '../team/newTeam/teamSlice';
import { setAllSeasons } from './seasonSlice';
import SeasonComingUp from './comingUp';
import PrintPastSeason from './handlePastSeason';
import HandlePastSeasons from './handlePastSeason';

function Season() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [time, setTime] = useState('');
    const [age, setAge] = useState('');
    const [duration, setDuration] = useState('');
    const [stateSessionSplit, setStateSessionSplit] = useState(1);
    const [sessionType, setsessionType] = useState('');
    const [createSession, setCreateSession] = useState(false);
    const [excludeWarmup, setExcludeWarmup] = useState(false)
    const [seasonStarted, setSeasonStarted] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [date, setDate] = useState();
    const [endDate, setEndDate] = useState();
    const [nrOfGames, setNrOfGames] = useState(0);
    const [openSetupGames, setOpenSetupGames] = useState(false);
    const [seasonTypes, setSeasonTypes] = useState([]);
    const [seasonLoading, setSeasonLoading] = useState(false);
    const [seasonTypesLoading, setSeasonTypesLoading] = useState(false);
    const [openNotification, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [additionalMessage, setAdditionalMessage] = useState('');
    const [season, setSeason] = useState('');
    const [opponent, setOpponent] = useState('')
    const [venue, setVenue] = useState('')
    // const [typeOfGame, setTypeOfGame] = useState('')
    const [gameFields, setGameFields] = useState([]);
    const [typeOfGame, setTypeOfGame] = useState('');
    const [teamSeasonId, setTeamSeasonID] = useState(-1);
    const [teamId, setTeamId] = useState('');
    const [activeComponent, setActiveComponent] = useState('')
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const stateSeasonStarted = useSelector((state) => state.season.seasonStarted)
    const setStateOpenSetupGames = useSelector((state) => state.season.openSetupGames)
    const availableTeams = useSelector((state) => state.team.availableTeams)
    const activeSeason = useSelector((state) => state.season.activeSeason);
    const changed = useSelector((state) => state.season.changed);
    const schoolId = parseInt(useSelector((state) => state.user.profile.school))
    const ageGroup = parseInt(useSelector((state) => state.user.profile.ageGroup))
    const allSeasons = parseInt(useSelector((state) => state.season.allSeasons))
    const teamsSeasons = useSelector((state) => state.season.teamsSeasons)
    const somethingChanged = useSelector((state) => state.season.somethingChanged)


    // useEffect((async) => {
    //     alert('triggered')
    //     availableTeams.forEach(async (team) => {
    //         const fetchSeasons = async () => {
    //             try {
    //                 await fetchSeason(team.id).then((response) => {
    //                     console.log('Response in UseEffect: ', response)
    //                 })
    //                     .finally(() => {
    //                         setIsLoading(false);
    //                         console.log("SETTING CHANGED TO FALSE IN USEFFECT")
    //                         dispatch(setChanged(false))

    //                     });
    //             } catch (error) {
    //                 console.log('Error: ', error)
    //             }
    //         }
    //         fetchSeasons();
    //     });
    // }, [somethingChanged])

    //NEW ONE

    // useEffect(() => {
    //     setIsLoading(true);

    //     if (!somethingChanged && teamsSeasons.length > 0) {
    //         // No need to make an API call, use the stored seasons
    //         const team = availableTeams.find((team) => team.name === activeSeason);

    //         if (team !== undefined) {
    //             const localTeamID = team.id;
    //             const storedSeason = teamsSeasons.find((season) => season.id === localTeamID);

    //             if (storedSeason) {
    //                 setSeason(storedSeason.season);
    //             } else {
    //                 setError(true);
    //                 setSeason(null);
    //                 dispatch(setStateSeasonStarted(false));
    //                 setSeasonStarted(false);
    //                 setNotificationMessage('No Season found for this team');
    //                 setNotificationOpen(true);
    //             }
    //             // Set teamSeasonId here
    //             setTeamSeasonID(team ? team.seasonId : -1);
    //         }
    //     } else {
    //         // Make API call for each available team
    //         availableTeams.forEach(async (team) => {
    //             const fetchSeasons = async () => {
    //                 try {
    //                     await fetchSeason(team.id).then((response) => {
    //                         console.log('Response in UseEffect: ', response);
    //                     });
    //                 } catch (error) {
    //                     console.log('Error: ', error);
    //                 }
    //             };
    //             fetchSeasons();
    //         });
    //     }

    //     setIsLoading(false);
    // }, [somethingChanged, availableTeams, activeSeason, teamsSeasons]);


    /////



    // ////Untested one
    // useEffect(() => {
    //     setIsLoading(true)
    //     //This means its probably when the component mounts
    //     if (teamsSeasons.length < 1 && somethingChanged === false) {
    //         availableTeams.forEach(team => {
    //             const fetchSeasons = async () => {
    //                 try {
    //                     await fetchSeason(team.id).then((response) => {
    //                         console.log('Response in UseEffect: ', response)
    //                     })
    //                         .finally(() => {
    //                             setIsLoading(false);
    //                             console.log("SETTING CHANGED TO FALSE IN USEFFECT")
    //                             dispatch(setChanged(false))

    //                         });
    //                 } catch (error) {
    //                     console.log('Error: ', error)
    //                 }
    //             }
    //             fetchSeasons();

    //         });
    //     } else if (somethingChanged === true) {
    //         //something has changed within the seasons, get all seasons and update the state

    //     }
    //     else {
    //         //This means there are seasons and nothing has changed - don't make api call again, 
    //         //only read from state
    //         let hasSeason;
    //         const team = availableTeams.find((team) => team.name === activeSeason);
    //         setTeamSeasonID(team ? team.seasonId : 'No Season ID')
    //         console.log('Team in useEffect in Season: ', team)

    //         if (team !== undefined) {
    //             setTeamId(team.id);
    //             const localTeamID = team.id;

    //             team.seasonId === -1 && setStateOpenSetupGames === false ? (hasSeason = false) : (hasSeason = true);

    //             if (hasSeason) {
    //                 //Find the season in state
    //                 const something = teamsSeasons.find((season) => season.id === localTeamID)
    //                 setSeason(something.season)
    //             } else {
    //                 setError(true);
    //                 setSeason(null)
    //                 dispatch(setStateSeasonStarted(false))
    //                 setSeasonStarted(false)
    //                 setNotificationMessage('No Season found for this team');
    //                 setNotificationOpen(true);
    //             }
    //         }
    //     }

    //     setIsLoading(false)
    // }, [somethingChanged, availableTeams, activeSeason])

    ///////

    useEffect(() => {
        console.log('TeamSeason in useEffect: ', teamSeasonId)
    }, [teamSeasonId])



    ///OLD ONE
    useEffect(() => {

        if (changed) {
            const fetchTeamsData = async () => {
                setIsLoading(true);
                await fetchTeams();
                setIsLoading(false)
            }
            fetchTeamsData();
            //Reset it back to false
            dispatch(setChanged(false))
        }

        console.log("SOMETHING TRIGGERED A REFERSH")
        let hasSeason;
        const team = availableTeams.find((team) => team.name === activeSeason);
        setTeamSeasonID(team ? team.seasonId : 'No Season ID')
        console.log('TeamSeasonID: ', team?.seasonId)
        console.log('Team in useEffect in Season: ', team)
        if (team !== undefined || changed === true) {
            setTeamId(team.id);
            const localTeamID = team.id;

            team.seasonId === -1 && setStateOpenSetupGames === false ? (hasSeason = false) : (hasSeason = true);

            if (hasSeason) {
                const fetchData = async () => {
                    setIsLoading(true);

                    try {
                        await fetchSeason(localTeamID).then((response) => {
                            console.log('Response in UseEffect: ', response)

                        })
                            .finally(() => {
                                setIsLoading(false);
                                console.log("SETTING CHANGED TO FALSE IN USEFFECT")
                                dispatch(setChanged(false))
                            });
                    } catch (error) {
                        console.log('Error trying to get Data');
                    }
                    finally {
                        setIsLoading(false)
                    }
                };

                fetchData();
            } else {
                setError(true);
                setSeason(null)
                dispatch(setStateSeasonStarted(false))
                setSeasonStarted(false)
                setNotificationMessage('No Season found for this team');
                setNotificationOpen(true);
            }
        }
    }, [activeSeason, stateSeasonStarted, refresh, changed]);


    // // When the component mounts, get the season types

    useEffect(() => {
        fetchSeasonTypes();
    }, [])

    async function refreshSeason() {
        setIsLoading(true)
        await fetchTeams()
        setIsLoading(false)
    }

    //////////////
    //If there are errors look at the combo of season and stateSeasonStarted
    //////////////

    useEffect(() => {
        setIsLoading(true)

        console.log('VALUES IN USEEFFECT: season: ', season, ' stateSeasonStarted: ', stateSeasonStarted, ' setStateOpenSetupGames: ', setStateOpenSetupGames)
        // fetchSeasonTypes()
        if (season !== null && teamSeasonId === 999) {
            setActiveComponent(<SeasonComingUp season={season} refreshSeason={refreshSeason} />);
        }
        else if (season !== null && teamSeasonId === -999) {
            setActiveComponent(<HandlePastSeasons season={season} teamId={teamId} seasonId={teamSeasonId} refreshSeason={refreshSeason} />);
        }
        else if (season !== null || stateSeasonStarted && !setStateOpenSetupGames && teamSeasonId !== -1 && teamSeasonId !== -999) {
            console.log('Season in if: ', season)
            setActiveComponent(<InSeasonComponent season={season} refreshSeason={refreshSeason} />);
        }
        else if (setStateOpenSetupGames) {
            setActiveComponent(<SetupGames nrOfGames={nrOfGames} refreshSeason={refreshSeason} />);
        }

        else {
            setActiveComponent(<CreateSeason seasonTypes={seasonTypes} />)
        }
        setIsLoading(false)
    }, [stateSeasonStarted, setStateOpenSetupGames, seasonTypes, season, changed]);


    const fetchSeasonTypes = async () => {
        setSeasonTypesLoading(true)
        setIsLoading(true);
        try {
            const response = await seasonRequests.getSeasonTypes();
            // const response = await teamRequests.getTeams(2, 14);

            console.log('Season Types received: ', response.data)

            if (response.status === 200) {

                setSeasonTypes(response.data)

            }
            else {
                console.log('In the else');

            }

        } catch (error) {
            console.error('Error fetching seasonTypes:', error);
        } finally {
            setIsLoading(false);
            setSeasonTypesLoading(false)
        }
    };



    const fetchTeams = async () => {
        setIsLoading(true);
        try {
            const response = await teamRequests.getTeams(schoolId, ageGroup);
            if (response.status === 200) {
                console.log('Response: ', response.data)
                dispatch(setAvailableTeams(response.data))
            }
        } catch (error) {
            console.error('Error fetching Teams:', error);
        } finally {
            setRefresh(!refresh);
            setIsLoading(false);

        }
    };


    const fetchSeason = async (localTeamId) => {
        console.log('In season for: ', localTeamId)
        setSeasonLoading(true)
        setIsLoading(true);
        try {
            const response = await seasonRequests.getSeason(localTeamId);
            // const response = await seasonRequests.getSeason(teamid);

            console.log('Season received in request: ', response.data)

            if (response.status === 200) {
                console.log('got response 200 for: ', localTeamId)
                dispatch(setBackendSeason(response.data));
                // console.log('BackendSeason should have been called')
                setSeason(response.data)
                dispatch(setTeamsSeasons({ id: localTeamId, season: response.data }))
                dispatch(setStateSeasonStarted(true))

            } else if (response.status === 400) {
                console.log('got response 400 for: ', localTeamId)
                console.log('Season does not exist');
                dispatch(setTeamsSeasons({ id: localTeamId, season: response }))
                dispatch(setStateSeasonStarted(false))
                setSeason(null)

            }
            else if (response.status === 404) {
                console.log('got response 404 for: ', localTeamId)
                console.log('Season does not exist');
                dispatch(setTeamsSeasons({ id: localTeamId, season: response }))
                dispatch(setStateSeasonStarted(false))
                // setSeason('')
                setSeason(null)
                setError(true)
                setNotificationMessage('No Season found for this team');
                setNotificationOpen(true);

            } else {
                console.log('In the else');

            }

        } catch (error) {

            dispatch(setTeamsSeasons({ id: localTeamId, season: error }))
            dispatch(setStateSeasonStarted(false))
            // setSeason('')
            setSeason(null)
            setError(true)

            setNotificationMessage('No Season found for this team');
            setNotificationOpen(true);
            console.error('Error fetching season:', error);
        } finally {
            setIsLoading(false);
            setSeasonLoading(false)
        }
    };

    const handleCloseConfirmationSnackbar = (event, reason) => {
        if (reason === 'clickaway') { return };
        setNotificationOpen(false);
    };

    return (
        <div>
            {
                seasonTypesLoading || seasonLoading || isLoading ?
                    <Loader />
                    :
                    activeComponent
            }

            <Snackbar
                open={openNotification}
                autoHideDuration={2000}
                onClose={handleCloseConfirmationSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseConfirmationSnackbar}
                    severity={error ? 'error' : 'success'}
                >
                    {notificationMessage}
                    <br />
                    {additionalMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default Season
