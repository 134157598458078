import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { setUserLoggedIn } from '../gameday/gamedaySlice';
import { useDispatch } from 'react-redux'
import SquadControlLogo from '../../assets/Squad Control Logo New.jpeg'
import { setApproved, setDevProfile, setManager, setProfile, setProfileCreated, setUserToken } from '../user/userSlice';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Copyright from '../../layouts/copyRight';
import { userRequests } from '../../services/api-requests';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Loader from '../../layouts/loader';

// Login Images
// import EGImage1 from '../../assets/LoginImages/estian1.jpg'
// import eg1 from '../../assets/LoginImages/eg1.jpg'
// import helpiesI1 from '../../assets/LoginImages/helpmekaarImg1ZoeMarais.jpg'
// import helpiesI2 from '../../assets/LoginImages/helpmekaarImg2ShaweMarais.jpg'
// import Img3 from '../../assets/LoginImages/helpmekaarImg2ShaweMarais.jpg'
// import eg2 from '../../assets/LoginImages/eg2.jpg'
// import Img4 from '../../assets/LoginImages/img4LizhaVanRensburg.jpg'
// import img5 from '../../assets/LoginImages/img5MarinaVanZyl.jpg'
// import img6 from '../../assets/LoginImages/img6NeashiaEls.jpg'
// import img7 from '../../assets/LoginImages/img7IrmaFerreira.jpg'
// import img8 from '../../assets/LoginImages/img8ElneKirton.jpg'
// import eg3 from '../../assets/LoginImages/eg3.jpg'
// import Menlo1 from '../../assets/LoginImages/Menlo1.jpg'
// import kloof1 from '../../assets/LoginImages/kloof1.jpg'
// import kloof2 from '../../assets/LoginImages/kloof2.jpg'
// import eg4 from '../../assets/LoginImages/eg4.jpeg'
// import eg5 from '../../assets/LoginImages/eg5.jpeg'
import { useState } from 'react';
import { useEffect } from 'react';



// const imageArray = [eg1, kloof2, Menlo1, EGImage1, kloof1, eg4, Img4, eg2, img5, img6, eg5, img8, eg3];
const defaultTheme = createTheme();

export default function SignInSide() {
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     // Change the current image index after each 10 seconds
  //     setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageArray.length);
  //   }, 10000);

    // Clear the interval when the component is unmounted or updated
  //   return () => clearInterval(intervalId);
  // }, []);


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const initialExcalidrawState = {
    elements: [],
    appState: { collaborators: [] }, // Ensure collaborators is an empty array
    scrollToContent: false,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const Email = data.get('email');
    const Password = data.get('password');
    console.log('Password: ', Password)

    setIsLoading(true)

    try {
      const response = await userRequests.loginUser(Email, Password);

      console.log('Response is: ', response);

      // Handle the response 
      if (response.status === 200) {
        const decodedToken = jwtDecode(response.data);
        console.log('Decoded Token: ', decodedToken)
        // dispatch(setUserToken(response.data))
        localStorage.setItem('token', response.data)
        // Initialize localStorage for Excalidraw with default empty state to prevent collaborator error when component mounts
        localStorage.setItem('excalidraw', JSON.stringify(initialExcalidrawState));

        dispatch(setUserLoggedIn(true));

        dispatch(setProfile(decodedToken))

        setIsLoading(false)
        if (decodedToken.role === 'Admin') {
          navigate('/AdminDashboard')
        }
        else {
          navigate('/Dashboard')
        }
      }

    } catch (error) {
      // Handle the error here
      setErrorMessage('Incorrect details');
      setOpenSnackbar(true);
      setEmailError(true);
      setPasswordError(true);
    }
    finally {
      // Hide loader after the async call (whether it succeeded or failed)
      setIsLoading(false);
    }
  };



  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}
        // changed
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CssBaseline />

        {/* <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden', // Ensure overflow doesn't affect the layout
          }}
        >
          <img
            draggable="false"
            loading='lazy'
            src={imageArray[currentImageIndex]}
            alt={`background-${currentImageIndex}`}
            style={{
              width: '100%',
              height: 'auto', // Allow the height to adjust based on the image's aspect ratio
              borderRadius: '10px',
              maxWidth: '100%', // Ensure the image doesn't exceed its natural width
              maxHeight: '100vh', // Limit the image height to the viewport height
            }}
          />
        </Grid> */}
        {/* sm={8} md={5}    */}
        {/* changed */}                                                           {/* changed */}
        <Grid item xs={12} sm={12} md={12} component={Paper} elevation={6} square style={{ maxWidth: '600px' }}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

            }}
          >
            <img draggable="false" loading='lazy' style={{ height: '180px', borderRadius: '10px', marginBottom: '20px' }} src={SquadControlLogo} alt='Squad Control Logo' />
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>

              {
                isLoading ?
                  <Loader />
                  :
                  <></>
              }
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                error={emailError} // Add error prop
                helperText={emailError ? 'Incorrect email or password' : ''}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={passwordError} // Add error prop
                helperText={passwordError ? 'Incorrect email or password' : ''}
              />

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container >
                <Grid item xs>
                  <Link href="/" variant="body2">
                    Back
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link href="/registerSchool" variant="body2">
                    Register School
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
              <br />

              {/* <Link href="/trialManager" variant="body2">
                {"Trial Manager"}
              </Link> */}
              <br />
              <br />
              <Copyright sx={{ mt: '50px' }} />
            </Box>
          </Box>
        </Grid>

      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity="error"
        >
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </ThemeProvider>
  );
}